import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Sidebar from "./Sidebar"
import wmOverview from "../../../assets/images/products/netversys/wm-overview.svg"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import { StaticImage } from "gatsby-plugin-image"
import DownloadBox from "./DownloadBox"
import FeatureSection from "../../App/FeatureSection"

const Overview = () => {
  const data = useStaticQuery(graphql`
          query CarrierauswahlHomepageQuery {
            carriers: allMarkdownRemark(
              filter: {fields: {collection: {eq: "carriers"}}, frontmatter: {active: {eq: true}}}
              ) {
              edges {
                node {
                  id
                  frontmatter {
                    active
                    carrier_support_email
                    carrier_name
                  }
                  fields {
                    collection
                    slug
                  }
                }
              }
            }
          }`)

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <StaticImage
                  src="../../../assets/images/products/netversys/devices/nv_dashboard_devices_all.png"
                  alt="NETVERSYS - Versandlogistiksoftware für Unternehmen"
                  placeholder="blurred"
                  width={900}
                />
              </div>

              <span className="products-sub-title" data-nosnippet="true">Cloudtechnologie & Mehr</span>
              <h1 className={"pt-3"}>NETVERSYS - Multi-Carrier Versandsoftware</h1>
              <p>
                NETVERSYS ist eine serverbasierte Multi-Carrier Versandsoftware mit moderner
                cloud-ready <Link to={"/technologie/"}>Technologie</Link>.
                Es sticht durch eine intelligente <Link
                to={"/versandsoftware/netversys/carrierauswahl/"}>Auswahl</Link> von vielen Carriern wie
                {data.carriers.edges.map(({ node: carrier }, i) => (
                  <span key={i}>
                      &nbsp;
                    {carrier.frontmatter.active &&
                      <Link
                        to={"/versandsoftware" + carrier.fields.slug}>{carrier.frontmatter.carrier_name}</Link>
                    }
                    {!carrier.frontmatter.active &&
                      carrier.frontmatter.carrier_name
                    }
                    {i + 1 !== data.carriers.edges.length ? ", " : ""}
                  </span>
                ))} und vielen weiteren während
                des Versandprozesses heraus. So kann anhand von Regeln wie Uhrzeit oder Entgelt-günstigst automatisch
                entschieden werden, welcher Frachtführer für welche Transaktion in Frage kommt. EDI (Electronic Data
                Interchange) basierte Abrechnungen sind übrigens bei NETVERSYS Standard, so dass auf eine tägliche
                Übergabe von Abrechnungsdokumenten verzichtet werden kann. Auch erweiterte Funktionen wie
                rechtskomforme <Link
                to={"/versandsoftware/netversys/gefahrgutversand/"}>Gefahrguthandhabung</Link> sind
                in NETVERSYS selbstverständlich enthalten.
              </p>

              <WichtigeMerkmale items={[
                "Alle relevanten Carrier",
                "Vollautomatisierung",
                "intelligente Fallbacks",
                "Least-Cost Option",
                "Customer-Choice"
              ]} image={<img src={wmOverview}
                             alt="Versandsoftware NETVERSYS Merkmale"
                             className={"ml-lg-4 ml-0"} />} />
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"right"}
                      image={<StaticImage
                        src="../../../assets/images/products/netversys/screenshots/nv_dashboard_framed_smaller.png"
                        alt="Auftragspool Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Leitstand in NETVERSYS"}
                      textBlock1={<span>Der Leitstand unserer Versandsoftware beherbergt alle Funktionen für den
                Teamleiter <Link to={"/versandlogistik/"}>Versandlogistik</Link>. Hier können Mandanten
                gepflegt, Regeln definiert und Carrierzuordnungen vorgenommen werden. Das Dashboard basiert auf HTML5
                und kann auf Geräten wie PCs, Tablets oder Smartphones mit einem Browser bedient werden. Dass das Design
                komplett responsive ist und sich automatisch auf die verschiedenen Bildschirmgrößen einstellt, versteht
                sich von selbst.</span>}
      />
      <FeatureSection backgroundColorClass={"bg-fffbf5"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netversys/plugin_screen.jpg"
                        alt="Pluginsystem"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Plugins in NETVERSYS"}
                      textBlock1={<span>Mit Hilfe des <Link
                        to={"/versandsoftware/netversys/plugins/"}>Pluginsystems</Link> ist
                            es möglich, komplett neue Funktionen
                            und Benutzerinteraktionen in NETVERSYS zu integrieren, ohne das eigentliche Produkt zu verändern.
                            Kunden nutzen das Pluginsystem um WMS Funktionalitäten direkt in NETVERSYS zu plazieren oder um
                            individuelle
                            Auswertungen in das System zu bringen. Und das Beste: Mit ein wenig Know-How erweitern Sie das System
                            selbst.</span>}
                      textBlock2={"Das Pluginsystem basiert auf speziellen Java Code. LOGENTIS bietet Dokumentationen und Beispielprojekte " +
                        "für die Entwicklung an. Ein einfaches Plugin ist innerhalb weniger Stunden entwickelt und durch " +
                        "einfaches kopieren des Java-Archivs in die Installation aktiviert. Sie wollen mehr Hilfe bei der " +
                        "Entwicklung des ersten Plugins? Kein Problem - LOGENTIS schult Ihre Entwickler live bei Ihnen vor Ort. " +
                        "Falls Sie bevorzugen, das Thema extern lösen zu lassen, übernimmt LOGENTIS die Entwicklung des Plugins " +
                        "selbstverständlich gern für Sie."}


      />
      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"right"}
                      image={<StaticImage
                        src="../../../assets/images/products/netversys/screenshots/nv_orderbuffer_framed.png"
                        alt="Auftragspool Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Auftragspool in NETVERSYS"}
                      textBlock1={"Der Auftragspool ist eine sehr gute Möglichkeit die Themen Vorsysteme und Datenasynchronität in einer " +
                        "eleganten Weise zu kombinieren. Über verschiedene Schnittstellen ist es möglich Versandaufträge nach " +
                        "NETVERSYS zu übertragen, ohne diese sofort abzuarbeiten bzw. den Versandprozess einleiten zu müssen. " +
                        "Dies gibt Freiheit wie ein konkreter Versandprozess aussehen soll, denn die Datenanlieferung wird so " +
                        "komplett vom eigentlichen Prozess am Arbeitsplatz entkoppelt."}
                      textBlock2={"Der Pool ist außerdem konfigurierbar in der Form, dass der Kunde entscheiden kann, ob abgearbeitete " +
                        "Aufträge sofort aus dem Pool entfernt werden sollen oder ob diese für eine bestimmte Zeit in der " +
                        "Versandsoftware vorhanden bleiben sollen. Dies ermöglicht, aus einem Auftrag aus dem Vorsystem " +
                        "mehrere Versandaufträge zu " +
                        "generieren und den Auftragspool als eine Art 'Versandorder Blueprin' Stapel zu nutzen."}
      />

      <section className="services-details-area d-lg-none">
        <div className="services-details-info p-5">
          <DownloadBox />
        </div>
      </section>
    </>
  )
}

export default Overview
